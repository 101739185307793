/*html {*/
/*  scroll-behavior: smooth;*/
/*}*/
/*@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';*/

:root{
  /*overflow: hidden;*/
  /*overflow-x: hidden;*/
  --darkPurple: #4C3198
}
.noscroll::-webkit-scrollbar{
  display: none;
}

html, body {
  /*overscroll-behavior-y: none;*/
  /*padding: 0px !important;*/
  position: relative;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

body::after {
  content: "";
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.violetColor {
  color: #4C3198FF;
}
.bgVioletColor {
  background-color: #4C3198FF;
}
.bgAddButton {
  background-image: linear-gradient(to right, #008664 0%, #00af64 51%, #008664 100%);

}

.successText{
  color: #008664 !important;
}

.errorText {
  color: #D62F1F !important;
}
.followUsBanner {
  height: 70px;
  width: 200px;
  background-image:url("/img/flus.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}
.header {
  height: 200px;
  background-color: #c9b9f6;
  /*background-image: url("/img/bgimage.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
  transition: all 0.3s ease-in-out;

}

.header-admin {
  height: 100px;
  background-color: #c9b9f6;
  /*background-image: url("/img/bgimage.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;

}

.header-m {
  height: 200px;
  background-color: #c9b9f6;
  /*background-image: url("/img/bgimage.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position: right 10% center;
  transition: all 0.3s ease-in-out;


}

a {
  text-decoration: none;
}

.appBar{
  background-color: white;
  z-index: 1255 !important;
  /*background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);*/

}
.searchBar{
  z-index: 1250 !important;
  background-color: white !important;
  border-radius: 30px;


}
.searchBar .gridItem{
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerTab {
  font-size: 13px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  position: relative; /* Ensure the ::after pseudo-element is positioned relative to this element */;
  color: gray !important;

}

.MuiTab-root.Mui-selected {
  /*color: #4C3198FF !important;*/
  color: #4C3198FF !important;
}
/*.css-13xuzx2-MuiButtonBase-root-MuiTab-root.Mui-selected {*/
/*  !*color: #4C3198FF !important;*!*/
/*  color: #4C3198FF !important;*/
/*}*/

.MuiTabs-root{
    /*border-radius: 50px !important;*/
  /*border:1px solid #c8c8c8*/
}

.headerTab::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 5px; /* Position it at the bottom */
  left: 0; /* Start the animation from the left */
  width: 0%; /* Initial width */
  height: 2px;
  background-color: #4C3198FF; /* Line color */
  transition: width 1s cubic-bezier(0.15, .7, 0.15, 1) ;
  margin-bottom: 10px;
}

.headerTab:hover::after {
  width: 100%; /* Expand to full width on hover */
}

.hoverAnimateSwing{
  /*animation: none;*/
  margin-right: 2px;
  margin-bottom: 2px !important;

}

.headerTab:hover .hoverAnimateSwing{
  animation: swing 1s; /* Trigger the swing animation */
  animation-iteration-count: 1; /* Run it only once */
}






.cs-no-scroll {
  overflow: hidden; /* Prevent scrolling */
  /*height: 100%; !* Ensure full height is utilized *!*/
  /*position: fixed; !* Fix position for the body to prevent overflow *!*/
  /*width: 100%; !* Ensure full width is utilized *!*/
  /*top: 0; !* Position at the top *!*/
  /*left: 0; !* Position at the left *!*/
  /*right: 0; !* Position at the right *!*/
  /*bottom: 0;*/
}


/*.header .bigLogo {*/
/*  background-size: contain;*/
/*  background-repeat: no-repeat;*/
/*  background-image: url("/img/bigLogo.png");*/
/*  background-position: left;*/
/*  min-height: 60px;*/
/*}*/

.bigLogo {
  z-index: 1256;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/img/bigLogo.png");
  background-position: left;
  min-height: 60px;

  position: absolute;
  top: 10px;
  left: 50px;
  height: 80px;
  width: 200px;
}

.bigLogo-m {
  z-index: 1256;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/img/bigLogo.png");
  background-position: left;
  min-height: 60px;

  position: absolute;
  top: 10px;
  left: 20px;
  height: 80px;
  width: 180px;
}

/*.header-m .bigLogo {*/
/*  background-size: contain;*/
/*  background-repeat: no-repeat;*/
/*  background-image: url("/img/bigLogo.png");*/
/*  background-position: left;*/
/*  min-height: 60px;*/
/*}*/

.vacancyItemImg {
  margin: 10px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  /*border: 1px solid darkgray !important;*/
  border-radius: 300px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3);
}

/*.card1{*/
/*  color: dimgrey;*/
/*  border-radius: 20px;*/
/*  background: #ffffff;*/
/*  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);*/
/*  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);*/
/*  !*padding: 10px;*!*/
/*  cursor: pointer;*/
/*}*/
.vacancyCard{
  color: dimgrey;
  border-radius: 15px;
  background: #ffffff;
  /*border: 0.1px solid #cfd8dc;*/
  border-bottom: 0.1px solid #cfd8dc;
  margin-bottom: 2px !important;
  /*box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);*/
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  /*padding: 10px;*/
  cursor: pointer;
}
.vacancyCard:hover{
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.actionPanel{
  color: dimgrey;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 -6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  /*padding: 10px;*/
  cursor: pointer;
  position: fixed !important;
  bottom: 0;
  left: 0;
  padding: 30px 10px;
  z-index: 99;
}

.verificationIcon{
  animation: wiggle 3s linear ;
  font-size: 120px !important;
  color: #008664;
  /*text-shadow: 0 5px 5px rgba(0,0,0,.08), 0 0 6px rgba(21, 101, 192, 0.3);*/
}

@keyframes wiggle {
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }

}

@keyframes linksAnimation {
  0%   {left:0px; top:0px;}
  25%  {left:0px; top:10px;}
  50%  {left:0px; top:0px;}
  75%  {left:0px; top:5px;}
  100% {left:0px; top:0px;}
}


.text-shine {
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(to right, #4c3198 0, #824fff 30%, #4c3198 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s linear infinite;
}
.addButton{
  background-image: linear-gradient(to right, #008664 0%, #00af64 51%, #008664 100%);
  margin: 10px;
  padding: 15px 20px !important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white !important;
  box-shadow: 0 -6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  border-radius: 100px !important;
  height: 35px;
  display: block;
}
.addButton:hover {
  /*background-position: right center; !* change the direction of the change here *!*/
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  color: #fff;
  text-decoration: none;
}
.cleanButton {
  background-image: linear-gradient(to right, #a90c0c 0%, #ec0000 51%, #a90c0c 100%) !important;
}
.continueButton {
  background-image: linear-gradient(to right, #240085 0%, #824fff 51%, #240085 100%) !important;
}
.disabledButton {
  background-image: linear-gradient(to right, #c9c9c9 0%, #676767 51%, #c9c9c9 100%) !important;
}


/*.text-shine-mainButton {*/
/*  font-size: 1.5em;*/
/*  font-weight: bold;*/
/*  font-family: 'Arial', sans-serif;*/
/*  text-transform: uppercase;*/
/*  text-align: center;*/
/*  background: linear-gradient(to right, #ffffff 0, #1d0065 30%, #ffffff 60%);*/
/*  -webkit-background-clip: text;*/
/*  -webkit-text-fill-color: transparent;*/
/*  animation: shineButton 2s linear infinite;*/
/*}*/




.btn{
  width: 150px;
  height: 40px;
  font-size: 25px;
  text-align: center;
  line-height: 100px;
  color: rgba(255,255,255,0.9);
  border-radius: 100px !important;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #1d0065);
  background-size: 600%;
  -webkit-animation: animeCvButton 6s linear infinite;
  animation: animeCvButton 6s linear infinite;
}

@-webkit-keyframes animeCvButton {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes animeCvButton {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 160px;
  }
  100% {
    background-position: 320px;
  }
}

.fade-in {
  opacity:0;  /* make things invisible upon start */
  animation:fadeIn ease-in 1;
  animation-fill-mode:forwards;
  animation-duration:.7s;
}
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.delay {
  animation-delay: 1.4s;
}



.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #1d0065; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .20em; /* Adjust as needed */
  animation:
          typing 3.5s steps(34, end),
          blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 24ch }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #1d0065; }
}

.flagEmoji {
  font-family: "Twemoji Country Flags", serif;
}




.scan-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.scan-container img {
  width: 100%;
  height: 100%;
}

.scan-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  animation: scan 2s linear infinite;
}

@keyframes scan {
  0% {
    top: -50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: -50%;
  }
}






@keyframes fadeInOut1 {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

@keyframes shine1 {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.fadeShineButton1 {
  animation: fadeInOut1 6s infinite ease-in-out, shine1 3s infinite linear;
  background-image: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0.4) 100%
  );
  background-size: 200% auto;
}

.disabledSection {
  pointer-events: none; /* Disable interaction */
  filter: grayscale(1); /* Grayed out appearance */
  opacity: 0.6; /* Optional: makes it a little more faded */
}



/*@keyframes shineButton {*/
/*  0% {*/
/*    background-position: 0;*/
/*  }*/
/*  50% {*/
/*    background-position: 80px;*/
/*  }*/
/*  100% {*/
/*    background-position: 160px;*/
/*  }*/
/*}*/





/*@plugin "daisyui/theme" {*/
/*  name: "dark";*/
/*  default: false;*/
/*  prefersdark: true;*/
/*  color-scheme: "dark";*/
/*  --color-base-100: oklch(25.33% 0.016 252.42);*/
/*  --color-base-200: oklch(23.26% 0.014 253.1);*/
/*  --color-base-300: oklch(21.15% 0.012 254.09);*/
/*  --color-base-content: oklch(97.807% 0.029 256.847);*/
/*  --color-primary: oklch(45% 0.24 277.023);*/
/*  --color-primary-content: oklch(96% 0.018 272.314);*/
/*  --color-secondary: oklch(67% 0.182 276.935);*/
/*  --color-secondary-content: oklch(93% 0.034 272.788);*/
/*  --color-accent: oklch(25% 0.09 281.288);*/
/*  --color-accent-content: oklch(78% 0.115 274.713);*/
/*  --color-neutral: oklch(35% 0.144 278.697);*/
/*  --color-neutral-content: oklch(92% 0.004 286.32);*/
/*  --color-info: oklch(62% 0.214 259.815);*/
/*  --color-info-content: oklch(28% 0.091 267.935);*/
/*  --color-success: oklch(76% 0.233 130.85);*/
/*  --color-success-content: oklch(26% 0.051 172.552);*/
/*  --color-warning: oklch(82% 0.189 84.429);*/
/*  --color-warning-content: oklch(41% 0.112 45.904);*/
/*  --color-error: oklch(70% 0.191 22.216);*/
/*  --color-error-content: oklch(27% 0.105 12.094);*/
/*  --radius-selector: 1rem;*/
/*  --radius-field: 2rem;*/
/*  --radius-box: 2rem;*/
/*  --size-selector: 0.3125rem;*/
/*  --size-field: 0.25rem;*/
/*  --border: 1.5px;*/
/*  --depth: 1;*/
/*  --noise: 0;*/
/*}*/




/*@plugin "daisyui/theme" {*/
/*  name: "bakujobs";*/
/*  default: true;*/
/*  prefersdark: false;*/
/*  color-scheme: "light";*/
/*  --color-base-100: oklch(100% 0 0);*/
/*  --color-base-200: oklch(98% 0 0);*/
/*  --color-base-300: oklch(95% 0 0);*/
/*  --color-base-content: oklch(12% 0.042 264.695);*/
/*  --color-primary: oklch(45% 0.24 277.023);*/
/*  --color-primary-content: oklch(93% 0.034 272.788);*/
/*  --color-secondary: oklch(67% 0.182 276.935);*/
/*  --color-secondary-content: oklch(93% 0.034 272.788);*/
/*  --color-accent: oklch(25% 0.09 281.288);*/
/*  --color-accent-content: oklch(78% 0.115 274.713);*/
/*  --color-neutral: oklch(35% 0.144 278.697);*/
/*  --color-neutral-content: oklch(92% 0.004 286.32);*/
/*  --color-info: oklch(62% 0.214 259.815);*/
/*  --color-info-content: oklch(28% 0.091 267.935);*/
/*  --color-success: oklch(76% 0.233 130.85);*/
/*  --color-success-content: oklch(26% 0.051 172.552);*/
/*  --color-warning: oklch(82% 0.189 84.429);*/
/*  --color-warning-content: oklch(41% 0.112 45.904);*/
/*  --color-error: oklch(70% 0.191 22.216);*/
/*  --color-error-content: oklch(27% 0.105 12.094);*/
/*  --radius-selector: 1rem;*/
/*  --radius-field: 2rem;*/
/*  --radius-box: 2rem;*/
/*  --size-selector: 0.3125rem;*/
/*  --size-field: 0.25rem;*/
/*  --border: 1.5px;*/
/*  --depth: 1;*/
/*  --noise: 0;*/
/*}*/


/*Updated Color Palette*/
/*Primary Violet (Main Color):*/

/*Hex: #4C3198*/
/*RGB: 76, 49, 152*/
/*Use this for branding, headers, and key UI elements.*/
/*Soft Violet (for backgrounds or hover states):*/

/*Hex: #D8C7F5*/
/*RGB: 216, 199, 245*/
/*Primary Blue (slightly saturated, complementary to violet):*/

/*Hex: #3561DB*/
/*RGB: 53, 97, 219*/
/*Use for interactive elements like buttons and links.*/
/*Soft Blue (backgrounds or subtle highlights):*/

/*Hex: #C5DFFF*/
/*RGB: 197, 223, 255*/
/*Primary Red (for alerts or errors):*/

/*Hex: #D72638*/
/*RGB: 215, 38, 56*/
/*Bold enough to stand out but not too harsh.*/
/*        Soft Red (for error backgrounds or subtle warnings):*/

/*Hex: #FFC6CA*/
/*RGB: 255, 198, 202*/
/*Neutral Backgrounds:*/
/*Light Gray: #F4F4F4*/
/*Dark Gray (text or dividers): #2A2A2A*/


