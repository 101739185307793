/* General Container */
.container {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*min-height: 100vh;*/
    /*background: #ffffff;*/

    /*overflow: hidden;*/
    /*padding: 20px;*/
    /*width: 100%;*/
}

/* Ocean Container */
.ocean {
    max-width: 100%;
    position: absolute;
    top:0;
    left: 0;
    width: 2500px;
    height: 100px;
    background: #f5f1fd;
    overflow-x: hidden; /* Hides horizontal overflow */
    overflow-y: visible; /* Keeps vertical overflow visible */
}

/* Waves */
.wave {
    position: absolute;
    width: 200%;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%239370DB'/%3E%3C/svg%3E");
    opacity: 0.6;
    animation: wave 15s linear infinite;
}

.wave-2 {
    bottom: 0;
    opacity: 0.4;
    animation: wave 23s linear reverse infinite;
}

.wave-3 {
    bottom: 0;
    opacity: 0.2;
    animation: wave 26s linear infinite;
}

/* Blur Effect */
.blur-layer {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(0px);
    background: rgba(214, 204, 255, 0.7);
    pointer-events: none;
}

/* Keyframes for Wave Animation */
@keyframes wave {
    0% { transform: translateX(0); }
    50% { transform: translateX(-25%); }
    100% { transform: translateX(-50%); }
}
