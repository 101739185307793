

/* Base styles */
.custom-form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: white; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.custom-form-group {
    margin-bottom: 16px; /* Space between fields */
}

.custom-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500; /* Slightly bolder label */
    color: #333; /* Darker text color */
}

.custom-select,
.custom-input,
.custom-textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    transition: border-color 0.3s ease; /* Smooth border color transition */
    font-size: 18px;
}

.custom-input:focus,
.custom-select:focus,
.custom-textarea:focus {
    border-color: #6200ea; /* Purple border on focus */
    outline: none; /* Remove default outline */
}

.custom-checkbox,
.custom-radio {
    margin-right: 8px; /* Space between label and input */
}

.custom-button {
    background-color: #6200ea; /* Purple background */
    color: white; /* White text color */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px 15px; /* Padding for button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.custom-button:hover {
    background-color: #3700b3; /* Darker purple on hover */
}

@media (max-width: 600px) {
    .custom-form-container {
        padding: 15px;
    }

    .custom-button {
        width: 100%; /* Full-width button on small screens */
    }
}
