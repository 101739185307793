a {
    text-decoration: none;
    border-radius: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 99.5%;
    left: 0px;
}

.button {
    display: inline-block;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    padding: 3px 0px;
    font-size: 16px;
    height: 35px;
    line-height: 35px;
    color: #292929;
    font-weight: 800;
    letter-spacing: 1px;
    border: none;
    transition: all 0.2s ease-in-out;
    background-color: #FFFFFF;
}

.button span {
    text-align: center;
    display: block;
    vertical-align: middle;
    transition: all 0.25s;
}

.button span.hidden {
    opacity: 0;
    transform: translateY(-50%);
}

.button:hover span {
    opacity: 0;
    transform: translateY(-50%);
}

.button:hover span:last-child {
    opacity: 1;
    transform: translateY(-100%);
}

.gradient-border {
    position: relative;
    border-radius: 30px;
}

.gradient-border:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: 35px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
